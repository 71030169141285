import React from "react";
import Layout from '../components/layout';
import Service from "../components/service";
import { graphql } from 'gatsby';
import _ from 'lodash';
import Seo from "../components/seo";// da
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import Parts from '../components/parts';
import TextBlock from '../components/textblock';
import Contact from '../components/contact';

export default function ServicesPage({ data }) {

    let siteData = _.merge( _.pick(data.siteMetadata.siteMetadata,['description','title','logo','siteUrl','posturl','captchaurl','tagLine','cookieDebug','headerMenu']))

    let aboutData = _.merge( _.pick(data.global,['id','company','address','duns','email','tel','twitter','web','tagLine']),
                             _.pick(data.global.body.data,['body'] )
                          )


    let pageData = _.merge( _.pick(data.page.nodes[0],['id','description','title','slug','section','page','date','keywords']),
                            _.pick(data.page.nodes[0].body.data,['body']) )


    let partsData = _.orderBy( data.page.nodes[0].parts.map( item => {
                  //console.debug( item );
                  return _.merge( _.pick( item, ['id','title','order','page','layouttype','partId','anchor','options']),
                                  { image: (item.image) ? item.image.localFile : null },
                                  { text: (item.text) ? ( item.text.data.text ) : "" })
                }),["order"],["asc"]  )
    const textOptions = { margin: true, centre: false, centretitle: true, titlecapitalize: true }
   return (
     <Layout header="Services">
       <Seo  title={pageData.title}  keywords={pageData.keywords} description={pageData.description}/>
       <div className="site-container">
         <TextBlock title={pageData.description} mdstring={pageData.body} section="Services" options={ textOptions }/>
         <Parts key="Parts" anchor="parts" parts={partsData}/>
         <Service  key="Service" description={"Our Service Catalogue"} header={false} link={false}></Service>
         <Contact key="Contact" ></Contact>
       </div>
     </Layout>
   );
}

export const pageQuery = graphql`
   query {

      global: strapiGlobal {
        id
        company
        address
        duns
        email
        tel
        twitter
        web
        tagLine
        body {
          data {
            body
          }
        }
      }


      siteMetadata: site(siteMetadata: {}) {
        siteMetadata {
          description
          logo
          siteUrl
          title
          posturl
          captchaurl
          tagLine
          cookieDebug
        }
      }

      page: allStrapiPage(filter: {title: {eq: "Services"}}) {
        nodes {
          id
          title
          strapi_id
          slug
          section
          page
          description
          date
          body {
            data {
              body
            }
          }
          parts {
            id
            order
            page
            title
            text {
              data {
                text
              }
            }
            layouttype
            partId
            anchor
            options {
              parallax
              height
              centre
              centretitle
              margin
            }
            image {
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
          }
        }
      }

  }
`
